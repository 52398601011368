<template>
  <div
    class="bg-v4 section section-low-bottom section-low-top"
    :style="`background-color: var(--${vars.backgroundColor}) !important; color: var(--${vars.textColor});`"
  >
    <div class="container-default w-container">
      <div class="calculator-container">
        <div class="card form w-form form-block" style="background-color: var(--section_primary)">
          <div class="form-header">
            <h4
              :style="`color: var(--${vars.titleColor}) !important;`"
              class="display-2 color-neutral-100 mg-bottom-8px"
            >
              {{ vars.titleText }}
            </h4>
            <p :style="`color: var(--${vars.textColor}) !important;`" class="text-sm color-neutral-400 mg-bottom-24px">
              {{ vars.descriptionText }}
            </p>
          </div>

          <div class="calculator-form">
            <div class="form-field-wrapper">
              <label class="color-neutral-400">Enter the number of valuations you currently do per month:</label>
              <input
                v-model="formData.valuations"
                type="number"
                class="input dark w-input h-100 w-100"
                placeholder="Enter number"
              />
            </div>

            <div class="form-field-wrapper">
              <label class="color-neutral-400">Enter your current valuation to instruction conversion rate:</label>
              <input
                v-model="formData.conversionRate"
                type="number"
                class="input dark w-input h-100 w-100"
                placeholder="Enter %"
              />
            </div>

            <div class="form-field-wrapper">
              <label class="color-neutral-400">Enter your current Instruction to Completion rate:</label>
              <input
                v-model="formData.completionRate"
                type="number"
                class="input dark w-input h-100 w-100"
                placeholder="Enter %"
              />
            </div>

            <div class="form-field-wrapper">
              <label class="color-neutral-400">Enter your current avg sales fee in £'s:</label>
              <input
                v-model="formData.avgFee"
                type="number"
                class="input dark w-input h-100 w-100"
                placeholder="Enter amount"
              />
            </div>

            <button
              @click="calculateRevenue"
              class="w-button btn-primary white !tw-mr-[unset] tw-mt-4 !tw-w-full !tw-py-4"
            >
              Calculate Potential Revenue
            </button>
          </div>

          <transition name="fade-slide">
            <div v-if="result" class="result-section tw-w-full">
              <div class="result-card">
                <div class="result-item">
                  <span class="result-label">Current Monthly Revenue</span>
                  <h4 class="display-4 color-neutral-100 mg-bottom-12px">
                    £{{ formatNumber(result.originalRevenue) }}
                  </h4>
                </div>

                <div class="result-item highlight">
                  <span class="result-label">New Monthly Revenue</span>
                  <h4 class="display-4 color-neutral-100 mg-bottom-12px">£{{ formatNumber(result.newRevenue) }}</h4>
                  <span class="result-badge"> +{{ result.growthPercentage }}% </span>
                </div>

                <div class="result-item">
                  <span class="result-label">Monthly Increase</span>
                  <h4 class="display-4 color-neutral-100 mg-bottom-12px">
                    £{{ formatNumber(result.monthlyIncrease) }}
                  </h4>
                </div>

                <div class="result-item annual">
                  <span class="result-label">Annual Revenue Increase</span>
                  <h4 class="display-4 color-neutral-100 mg-bottom-12px">£{{ formatNumber(result.annualIncrease) }}</h4>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CalculateLSRevenue',

  mixins: [ComponentMixin],

  data() {
    return {
      formData: {
        valuations: null as number | null,
        conversionRate: null as number | null,
        completionRate: null as number | null,
        avgFee: null as number | null,
      },
      result: null as any,
    };
  },

  methods: {
    formatNumber(num: number): string {
      return num.toLocaleString('en-GB');
    },

    calculateRevenue() {
      const { valuations, conversionRate, completionRate, avgFee } = this.formData;

      if (!valuations || !conversionRate || !completionRate || !avgFee) {
        return;
      }

      const conversionRateDecimal = conversionRate / 100;
      const completionRateDecimal = completionRate / 100;

      const originalRevenue = valuations * conversionRateDecimal * completionRateDecimal * avgFee;
      const newValuations = valuations * 1.2;
      const newConversionRate = conversionRateDecimal * 1.05;
      const newAvgFee = avgFee * 1.15;
      const newRevenue = newValuations * newConversionRate * completionRateDecimal * newAvgFee;
      const monthlyIncrease = newRevenue - originalRevenue;
      const annualIncrease = monthlyIncrease * 12;
      const growthPercentage = ((newRevenue - originalRevenue) / originalRevenue) * 100;

      this.result = {
        originalRevenue: Math.round(originalRevenue),
        newRevenue: Math.round(newRevenue),
        monthlyIncrease: Math.round(monthlyIncrease),
        annualIncrease: Math.round(annualIncrease),
        growthPercentage: Math.round(growthPercentage),
      };
    },
  },
});
</script>

<style scoped>
.calculator-container {
  max-width: 600px;
  margin: 0 auto;
}

.calculator-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
}

.form-header {
  padding: 24px 24px 0;
}

.result-section {
  padding: 0 24px 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 24px;
}

/* Animation classes */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.5s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

/* Adjust text sizes */
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.display-2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

/* Remove unused grid styles */
.calculator-layout,
.calculator-form-section,
.calculator-results-section {
  display: none;
}

.form-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.result-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.result-item {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  position: relative;
}

.result-item.highlight {
  background-color: rgba(var(--primary-rgb), 0.1);
  border: 1px solid rgba(var(--primary-rgb), 0.2);
}

.result-item.annual {
  background-color: rgba(var(--primary-rgb), 0.15);
  border: 1px solid rgba(var(--primary-rgb), 0.25);
  margin-top: 8px;
}

.result-label {
  color: var(--text_secondary);
  margin-bottom: 4px;
}

.result-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px 8px;
  background-color: rgba(var(--primary-rgb), 0.2);
  color: var(--primary);
  border-radius: 16px;
  font-weight: 600;
}

.form-field-wrapper input {
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.form-field-wrapper input:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.2);
}

@media (max-width: 768px) {
  .calculator-container {
    max-width: 100%;
  }

  .form-header {
    padding: 16px 16px 0;
  }

  .calculator-form {
    padding: 16px;
  }

  .result-section {
    padding: 16px;
  }
}
</style>
