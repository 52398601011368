<template>
  <div
    class="section vertical-marquee-right-section"
    :style="`background-color: var(--${vars.backgroundColor}) !important; color: var(--${vars.textColor});`"
  >
    <div class="container-default position-relative---z-index-1 w-container">
      <div class="grid-2-columns _1-2fr---1fr">
        <div
          id="w-node-beef93c0-e385-cb6a-391d-abcb575b8063-6ab70d60"
          data-w-id="beef93c0-e385-cb6a-391d-abcb575b8063"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            opacity: 1;
            transform-style: preserve-3d;
          "
        >
          <h2 class="display-2 mg-bottom-12px">{{ vars.titleText }}</h2>
          <div class="inner-container _596px">
            <template v-if="!vars.textHtml">
              <p
                v-for="(item, index) in descriptions"
                :key="index"
                :style="`color: var(--${vars.textColor}) !important;`"
                data-w-id="6f66d181-5a80-7b1a-9ff9-9de709f6d32d"
                style="
                  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                  opacity: 1;
                  transform-style: preserve-3d;
                "
                class="mg-bottom-40px"
              >
                {{ item.description }}
              </p>
            </template>
            <template v-else>
              <div v-html="vars.textHtml"></div>
            </template>
            <nuxt-link :to="vars.buttonLinkUrl" :external="true" class="w-button" :class="`${vars.buttonTypeText}`">
              {{ vars.buttonLabelText }}
            </nuxt-link>
          </div>
        </div>
      </div>
      <div data-w-id="1d4ea52e-1f6c-b4a7-dda6-aff78d053a90" class="vertical-marquee-main-container" style="opacity: 1">
        <div
          id="w-node-_1d4ea52e-1f6c-b4a7-dda6-aff78d053ab3-8d053a85"
          data-w-id="1d4ea52e-1f6c-b4a7-dda6-aff78d053ab3"
          class="vertical-marquee-column hidden-on-mbl hidden-on-tablet"
          style="
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            will-change: transform;
          "
        >
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
        </div>
        <div
          id="w-node-_1d4ea52e-1f6c-b4a7-dda6-aff78d053ab3-8d053a85"
          data-w-id="1d4ea52e-1f6c-b4a7-dda6-aff78d053ab3"
          class="vertical-marquee-column hidden-on-mbl hidden-on-tablet"
          style="
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            will-change: transform;
          "
        >
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              width="120"
              height="120"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
        </div>
        <div
          id="w-node-_20497139-1d00-8934-131b-f1b6fa60ecd5-8d053a85"
          data-w-id="20497139-1d00-8934-131b-f1b6fa60ecd5"
          class="vertical-marquee-column hidden-on-desktop"
        >
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
          <div class="vertical-marquee-items-container"></div>
        </div>
        <div
          id="w-node-e4ef4fc2-bd5c-39cf-a15d-59811a7d6715-8d053a85"
          data-w-id="e4ef4fc2-bd5c-39cf-a15d-59811a7d6715"
          class="vertical-marquee-column hidden-on-desktop"
          style="
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            will-change: transform;
          "
        >
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
          <div class="vertical-marquee-items-container"></div>
        </div>
        <div
          id="w-node-_1d4ea52e-1f6c-b4a7-dda6-aff78d053aa2-8d053a85"
          data-w-id="1d4ea52e-1f6c-b4a7-dda6-aff78d053aa2"
          class="vertical-marquee-column hidden-on-mbl hidden-on-tablet"
          style="
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            will-change: transform;
          "
        >
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480ddee026f357cefda73cb_go-marquee-logo-sleek-webflow-ecommerce-template-iceberg-digital.svg"
              loading="eager"
              alt=""
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Overview',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
