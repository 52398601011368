<template>
  <div
    class="section"
    :style="`background-color: var(--${vars.backgroundColor}) !important; color: var(--${vars.textColor});`"
  >
    <div class="container-default w-container">
      <h2
        data-w-id="5e816850-6d98-3acc-c86e-9a6960e7bd9e"
        style="
          transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
            skew(0deg, 0deg);
          opacity: 1;
          transform-style: preserve-3d;
        "
        class="display-2 text-center mg-bottom-48px"
      >
        Get in touch with us directly
      </h2>
      <div class="grid-3-columns _1-col-tablet">
        <div
          v-for="(item, index) in contacts"
          :key="index"
          id="w-node-d8575931-7d36-fb59-a442-93f4f3997ee3-9e5c1e5a"
          data-w-id="d8575931-7d36-fb59-a442-93f4f3997ee3"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            opacity: 1;
            transform-style: preserve-3d;
          "
        >
          <nuxt-link
            id="w-node-d8575931-7d36-fb59-a442-93f4f3997ee3-3bedcd9e"
            data-w-id="1514722a-1803-ed3a-e2ea-ead3ba3a7763"
            :to="`/${item.contact_link}`"
            class="card contact-card-link w-inline-block"
          >
            <div class="mg-bottom-20px">
              <img :src="item.contact_icon" loading="eager" alt="contact icon" class="shadow-02 border-radius-12px" />
            </div>
            <h3 class="card-link-title display-3 mg-bottom-4px" style="color: rgb(7, 7, 7)">
              {{ item.contact_title }}
            </h3>
            <p class="color-neutral-600 mg-bottom-32px">{{ item.contact_description }}</p>
            <div class="mg-top-auto">
              <div class="text-300 bold color-neutral-800">{{ item.contact_button }}</div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'GetInTouch',

  mixins: [ComponentMixin],

  computed: {
    contacts() {
      return this.groupedVariables.contacts;
    },
  },
});
</script>
