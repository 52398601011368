<template>
  <div>
    <div
      class="top-section bg-v6"
      :style="`background-color: var(--${vars.backgroundColor || ''}); color: var(--${vars.textColor || ''})`"
    >
      <div class="container-default w-container">
        <div class="text-center mg-bottom-48px">
          <div class="inner-container _618px center">
            <h1
              data-w-id="98e6305b-e1d2-b1df-749e-a43f10e6e692"
              style="
                transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                  skew(0deg, 0deg);
                opacity: 1;
                transform-style: preserve-3d;
              "
              class="display-1 color-neutral-100 mg-bottom-12px"
            >
              {{ vars.titleText }}
            </h1>
            <p
              data-w-id="973a784e-a42b-df31-7b09-0f2ffc165839"
              style="
                transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                  skew(0deg, 0deg);
                opacity: 1;
                transform-style: preserve-3d;
              "
              class="color-neutral-300 mg-bottom-0 tw-text-center"
            >
              {{ vars.descriptionText }}
            </p>
          </div>
        </div>
        <div class="mg-bottom-72px">
          <div class="grid-2-columns _1-6fr---1fr gap-row-48px">
            <div
              id="w-node-e3b16efc-0a58-e800-0c2f-d177d737383e-bcdec308"
              data-w-id="e3b16efc-0a58-e800-0c2f-d177d737383e"
              style="
                transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                  skew(0deg, 0deg);
                opacity: 1;
                transform-style: preserve-3d;
              "
              class="w-dyn-list"
            >
              <div role="list" class="w-dyn-items">
                <div role="listitem" class="w-dyn-item">
                  <nuxt-link
                    :external="true"
                    data-w-id="50d0e22d-9b44-2700-bf37-1dc985627658"
                    :to="`/blogs/${blogs[0].url}`"
                    class="text-decoration-none color-transition-none flex-vertical w-inline-block"
                    ><div class="card-link---image-wrapper border-radius-26px">
                      <library-image
                        :src="blogs[0].largeImage"
                        loading="eager"
                        :alt="blogs[0].title"
                        class="card-link---image"
                        :w-ratio="19"
                        :h-ratio="10"
                        style="
                          transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
                            rotateZ(0deg) skew(0deg, 0deg);
                          transform-style: preserve-3d;
                        "
                      />
                    </div>
                    <div class="card blog-link-text-card featured-top" style="background-color: #212121">
                      <h2
                        class="card-link-title-white mg-bottom-0 tw-mb-2 tw-text-4xl"
                        style="color: rgb(255, 255, 255)"
                      >
                        {{ blogs[0].title }}
                      </h2>
                      <p class="color-neutral-400">
                        {{ blogs[0].description }}
                      </p>
                    </div></nuxt-link
                  >
                </div>
              </div>
            </div>
            <div
              id="w-node-ffe7f2e8-4cac-c443-550e-ab7148661d95-48661d95"
              data-w-id="ffe7f2e8-4cac-c443-550e-ab7148661d95"
              class="card top-section-newsletter-card"
              style="
                transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                  skew(0deg, 0deg);
                transform-style: preserve-3d;
                opacity: 1;
                background-color: #1f1f1f;
              "
            >
              <div class="mg-bottom-24px">
                <img
                  src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/644a6cebe40c046c9e3fdfff_newsletter-subscribe-card-icon-sleek-webflow-ecommerce-template.svg"
                  loading="eager"
                  alt="Subscribe To Our Newsletter - Sleek X Webflow Template"
                  class="max-w-64px-mbl max-w-48px-mbp"
                />
              </div>
              <div class="inner-container">
                <h3 class="heading-h2-size color-neutral-100">Subscribe to our newsletter</h3>
                <div class="mg-bottom-8px color-neutral-400 mg-bottom-24px mg-top-24px">
                  Stay informed with the latest news and updates from the Iceberg Digital team.
                </div>
              </div>
              <div class="mg-bottom-0 w-form">
                <library-form
                  v-if="form"
                  :component="component"
                  version="theme0"
                  :form="form"
                  :is-form-name-visible="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-low-top section-low-bottom">
      <div id="latest-news" class="container-default w-container">
        <div class="inner-container _600px-tablet center">
          <h2 id="w-node-_70abe8c4-607c-9898-bf83-2880668936b8-9e5c1f8c" class="display-12 tw-mb-12">Latest news</h2>
          <div class="w-dyn-list">
            <div class="grid-3-columns gap-row-56px _1-col-tablet w-dyn-items">
              <div v-for="(blog, index) in blogs" :key="`blog-${index}`" class="flex w-dyn-item">
                <lazy-theme0-blogs-blog-card :blog="blog" :index="index" />
              </div>
            </div>
            <div v-if="isPaginationVisible">
              <lazy-library-blogs-pagination
                :component="component"
                version="v2"
                pagination-active-bg="#070707"
                pagination-bg="#fff"
              />
            </div>
            <div
              v-if="!vars.isPaginationEnabledBool"
              class="tw-flex tw-w-full tw-items-center tw-justify-center tw-pt-4"
            >
              <nuxt-link class="n-primary" :to="ROUTES.BLOGS" :external="true">View All Blogs</nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import type { Component } from '~/types';
import Loader from '~/components/common/Loader.vue';
import NotFound from '~/components/theme4/base/NotFound.vue';
import indexGlobal from '~/mixins/index.global';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import SearchBox from '~/components/common/SearchBox.vue';
import { useWebsiteStore } from '~/units/website/store';
import { ROUTES } from '~/constants/ui.constants';

export default defineNuxtComponent({
  name: 'Theme0BlogsPage',
  components: { SearchBox, NcButton, NotFound, Loader },

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  data() {
    return {
      form: null,
      variants: {
        v1: 'v1',
      },
      keyword: '',
      searchBoxPlaceholder: 'Search',
    };
  },

  created() {
    this.form = this.parseFormV2(this.vars.topForm);
  },

  computed: {
    ...mapState(useWebsiteStore, ['theme']),

    isLatestCarouselEnabled() {
      return this.vars.isLatestCarouselEnabledNumber;
    },

    isLatestCarouselVisible() {
      const route = useRoute();
      return this.isLatestCarouselEnabled && this.blogs.length !== 0 && !route.query.q && !route.query.page;
    },

    isPaginationEnabled() {
      return this.vars.isPaginationEnabledBool;
    },

    isPaginationVisible() {
      return this.isPaginationEnabled && !this.isBlogsLoading;
    },

    variant() {
      return useVariant(this.component);
    },

    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },

    titleHtml() {
      return this.vars.titleHtml;
    },

    ROUTES() {
      return ROUTES;
    },
  },

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchBlogsInPage(props.component);
  },

  mounted() {
    const route = useRoute();
    if (route.query.q) {
      this.keyword = route.query.q as string;
    }
  },

  methods: {
    async onSearched() {
      const blogsStore = useBlogsStore();
      await blogsStore.searchBlogs(this.keyword);
    },
  },

  watch: {
    async $route(a, b) {
      if (a.query.q !== b.query.q) {
        await fetchBlogsManager(this.component, a.query && a.query.q ? a.query.q : null);
      }
    },
  },
});
</script>
