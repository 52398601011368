<template>
  <div>
    <div
      v-if="blog"
      class="top-section bg-v9"
      :style="`background-color: var(--${vars.backgroundColor || ''}) !important; color: var(--${vars.textColor || ''})`"
    >
      <div class="container-default w-container">
        <div class="text-center mg-bottom-48px">
          <h1 class="display-13 color-neutral-100 f-league-spartan-600">{{ blog.title }}</h1>
          <div class="inner-container _700px center">
            <p class="color-neutral-801 mg-bottom-0 f-league-spartan-500">
              {{ blog.description }}
            </p>
          </div>
        </div>
        <NuxtImg
          v-if="!blog.video_link && blog.largeImage"
          :src="blog.largeImage"
          :alt="blog.title"
          class="border-radius-26px width-100"
        />
        <div class="w-video w-embed" v-if="blog.video_link">
          <library-iframe
            class="embedly-embed"
            :src="blog.video_link"
            scrolling="no"
            :title="blog.title"
            frameborder="0"
            allow="autoplay; fullscreen; encrypted-media; picture-in-picture;"
            allowfullscreen="true"
          ></library-iframe>
        </div>
      </div>
    </div>
    <div class="section bg-neutral-200 section-low-top section-low-bottom">
      <div class="container-default">
        <div class="inner-container _762px center" style="color: var(--text_primary)">
          <div class="w-richtext" v-html="blog.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Component } from '~/types';
import { ComponentMixin } from '~/mixins/component.mixin';
import { BlogMixin } from '~/units/blogs/mixins/blog.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import { ROUTES } from '~/constants/ui.constants';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'Theme0BlogDetail',

  mixins: [ComponentMixin, BlogMixin],

  data() {
    return {
      form: null,
    };
  },

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchSingleBlog(props.component);
  },

  computed: {
    ROUTES() {
      return ROUTES;
    },
  },
});
</script>

<style scoped></style>
