<template>
  <div class="section section-low-top section-low-bottom max-lg:tw-pb-52">
    <div class="container-default w-container">
      <div data-w-id="2f57113d-6574-1aee-aa80-28ea460601d2" :style="transformStyle" class="text-center">
        <div class="inner-container _706px center">
          <h2 class="display-2 tw-mb-4 tw-pb-0" :style="`color: var(--${vars.titleColor}) !important;`">
            {{ vars.titleText }}
          </h2>

          <template v-if="descriptions.length > 0">
            <p
              v-for="(item, index) in descriptions"
              :key="'desc-' + index"
              :style="`color: var(--${vars.textColor}) !important;`"
            >
              {{ item.description }}
            </p>
          </template>
        </div>
      </div>

      <div class="grid-1-column gap-row-28px mg-bottom-56px tw-pb-16">
        <div
          v-for="(group, groupIndex) in enhancedVisibleChunkedCards"
          :key="'group-' + groupIndex"
          :class="[getGridClass(group), groupIndex >= 2 && group.isDoubleLayout ? 'grid-group-padding' : '']"
        >
          <div
            v-for="(cardData, cardIndex) in group.cards"
            :key="'card-' + cardIndex"
            :class="[
              computeCardClass(group.isDoubleLayout, cardIndex),
              group.isDoubleLayout ? 'double-grid-card' : 'triple-grid-card',
            ]"
            :style="transformStyle"
            class="tw-h-full"
          >
            <div>
              <h3
                v-if="cardData.title"
                :class="['display-3 mg-bottom-10px', group.isDoubleLayout ? 'double-grid-title' : 'triple-grid-title']"
              >
                {{ cardData.title }}
              </h3>
              <h2 class="display-4 tw-mb-2 tw-h-[46px] md:tw-h-[52px]">{{ cardData.job }}</h2>
              <p
                v-if="cardData.description"
                :class="[
                  'mg-bottom-40px tw-line-clamp-[8] tw-h-[72px]',
                  group.isDoubleLayout ? 'double-grid-description' : 'triple-grid-description',
                ]"
              >
                {{ cardData.description }}
              </p>
            </div>

            <template v-if="cardData.video">
              <div
                class="tw-relative tw-w-full tw-overflow-hidden"
                :class="[
                  getMediaWrapperClass(group.isDoubleLayout, cardIndex),
                  group.isDoubleLayout ? 'double-grid-media' : 'triple-grid-media',
                ]"
              >
                <div :class="getAspectRatioClass(group.isDoubleLayout, cardIndex)">
                  <library-iframe
                    v-if="group.isDoubleLayout"
                    :src="cardData.video"
                    frameborder="0"
                    allowfullscreen
                    style="border-radius: 0px !important"
                  />
                  <library-iframe
                    v-else
                    class="tw-absolute tw-inset-0 tw-h-full tw-w-full"
                    :src="cardData.video"
                    frameborder="0"
                    allowfullscreen
                    style="border-radius: 0px !important"
                  />
                </div>
              </div>
            </template>
            <template v-else-if="cardData.background">
              <div
                class="tw-relative tw-w-full tw-overflow-hidden"
                :class="[
                  getMediaWrapperClass(group.isDoubleLayout, cardIndex),
                  group.isDoubleLayout ? 'double-grid-media' : 'triple-grid-media',
                ]"
              >
                <div :class="getAspectRatioClass(group.isDoubleLayout, cardIndex)">
                  <img
                    class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
                    :src="cardData.background"
                    loading="eager"
                    :alt="cardData.title"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div data-w-id="ddf51907-cdd4-05ef-2076-91b2db615056" :style="transformStyle" class="buttons-row center">
        <div v-if="hasMoreCards" class="max-md:tw-w-full">
          <button @click="loadMore" class="w-button btn-primary">{{ vars.loadMoreLabelText }}</button>
        </div>
        <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
          <nuxt-link
            :to="button.link"
            :external="true"
            class="w-button"
            :class="`${button.type} ${index === 0 ? 'button-row' : ''}`"
          >
            {{ button.label }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurAwards',
  mixins: [ComponentMixin],

  data() {
    return {
      transformStyle: 'transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);',
      currentPageVisible: 1,
      itemsPerPage: 5,
    };
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    cards() {
      return this.groupedVariables.cards;
    },
    chunkedCards() {
      return this.chunkCards(this.cards);
    },
    enhancedChunkedCards() {
      return this.chunkedCards.map((group) => ({
        cards: group,
        isDoubleLayout: group.length === 2,
      }));
    },
    enhancedVisibleChunkedCards() {
      return this.enhancedChunkedCards.slice(0, this.currentPageVisible * 2);
    },
    hasMoreCards() {
      return this.chunkedCards.length > this.currentPageVisible * 2;
    },
  },

  methods: {
    loadMore() {
      this.currentPageVisible++;
    },

    chunkCards(cards) {
      const pattern = [2, 3];
      const result = [];
      let i = 0;
      let patternIndex = 0;

      while (i < cards.length) {
        const chunkSize = pattern[patternIndex % pattern.length];
        const chunk = cards.slice(i, i + chunkSize);
        if (chunk.length > 0) {
          result.push(chunk);
        }
        i += chunkSize;
        patternIndex++;
      }
      return result;
    },

    getGridClass(group) {
      return group.isDoubleLayout ? 'grid-2-columns _1fr---1fr tw-w-full' : 'grid-3-columns _1-col-tablet tw-gap-4';
    },

    computeCardClass(isDoubleLayout, cardIndex) {
      if (isDoubleLayout) {
        return cardIndex === 0 ? 'card pd-52px---42px---22px' : 'card card-image-bottom-right';
      } else {
        if (cardIndex === 0) {
          return 'card features-card-small';
        } else if (cardIndex === 1) {
          return 'card features-card-small';
        } else {
          return 'card features-card-small pd-bottom-0';
        }
      }
    },

    getAspectRatioClass(isDoubleLayout, cardIndex) {
      if (isDoubleLayout) {
        return cardIndex === 0 ? 'tw-aspect-[16/9]' : '';
      } else {
        return 'tw-aspect-[9/16]';
      }
    },

    getMediaWrapperClass(isDoubleLayout, cardIndex) {
      if (isDoubleLayout) {
        return cardIndex === 0 ? 'portrait-media' : 'landscape-media';
      } else {
        return 'portrait-media';
      }
    },
  },
});
</script>

<style scoped>
.portrait-media {
  max-width: 360px;
  margin: 0 auto;
}

.landscape-media {
  max-width: 600px;
  margin: 0 auto;
}

.grid-2-columns._1fr---1fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: start;
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 991px) {
  .grid-2-columns._1fr---1fr {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

.double-grid-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.double-grid-title {
  font-size: 1.75rem;
}

.double-grid-description {
  font-size: 1.1rem;
  line-height: 1.6;
}

.double-grid-media {
  margin-top: auto;
}

/* Triple grid specific styles */
.triple-grid-card {
  height: 100%;
}

.triple-grid-title {
  font-size: 1.5rem;
}

.triple-grid-description {
  font-size: 1rem;
  line-height: 1.5;
}

.triple-grid-media {
  margin-top: 1rem;
}

.card.pd-52px---42px---22px {
  background: var(--section_secondary);
  border-radius: 1rem;
  padding: 2rem;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.portrait-media {
  width: 100%;
  max-width: none;
  margin: 0;
}

.portrait-media .tw-aspect-\[9\/16\] {
  overflow: hidden;
}

.card.card-image-bottom-right {
  padding: 36px !important;
}

.landscape-media {
  width: 100%;
  max-width: none;
  margin: 0;
}

.landscape-media .tw-aspect-\[16\/9\] {
  height: 300px;
  overflow: hidden;
}

.card h3.display-3 {
  font-size: 1.75rem;
  line-height: 1.2;
  margin: 0;
}

.card p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

.tw-absolute.tw-inset-0.tw-h-full.tw-w-full {
  object-fit: cover;
}

@media (max-width: 991px) {
  .grid-2-columns._1fr---1-42fr {
    grid-template-columns: 1fr;
  }

  .double-grid-title,
  .triple-grid-title {
    font-size: 1.5rem;
  }

  .double-grid-description,
  .triple-grid-description {
    font-size: 1rem;
  }
}

@media screen and (max-width: 991px) {
  .portrait-media .tw-aspect-\[9\/16\] {
    max-height: 100% !important;
  }
  .portrait-media {
    max-width: 100% !important;
    margin: 0 auto;
  }
  .landscape-media {
    max-width: 100% !important;
    margin: 0 auto;
  }
  .landscape-media .tw-aspect-\[16\/9\] {
    height: 100% !important;
  }
}
.grid-group-padding {
  padding-top: 3rem;
}
::v-deep(iframe) {
  border-radius: 0px !important;
}
</style>
