<template>
  <div class="section" :style="`background-color: var(--${vars.backgroundColor}) !important;`">
    <div class="container-default w-container">
      <div
        data-w-id="ecc47b67-ab0e-45ef-763b-fb5115706033"
        class="cta-card mg-bottom-40px tw-items-center tw-justify-center"
        style="opacity: 1"
      >
        <div
          data-w-id="ecc47b67-ab0e-45ef-763b-fb5115706034"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
        >
          <h2
            class="display-2 color-neutral-100 mg-bottom-12px"
            :style="`color: var(--${vars.titleColor}) !important;`"
          >
            {{ vars.titleText }}
          </h2>
          <template v-if="descriptions.length > 0">
            <p
              v-for="(item, index) in descriptions"
              :key="index"
              class="color-neutral-400 mg-bottom-40px"
              :style="`color: var(--${vars.textColor}) !important;`"
            >
              {{ item.description }}
            </p>
          </template>

          <div class="buttons-row tw-pt-4">
            <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
              <nuxt-link
                :to="button.link"
                :external="true"
                class="w-button"
                :class="`${button.type} ${index === 0 ? 'button-row' : null}`"
                v-bind="{ target: button.link.startsWith('http') ? '_blank' : '_self' }"
              >
                {{ button.label }}
              </nuxt-link>
            </div>
          </div>
        </div>
        <div
          id="w-node-ecc47b67-ab0e-45ef-763b-fb511570603e-15706031"
          data-w-id="ecc47b67-ab0e-45ef-763b-fb511570603e"
          class="cta-card---image-right-wrapper"
          style="
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
        ></div>
        <img
          :src="vars.cardImage"
          loading="eager"
          id="w-node-ecc47b67-ab0e-45ef-763b-fb511570603f-15706031"
          :alt="vars.titleText"
          class="image-2 min-w-full"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'PersonCard',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
