<template>
  <div id="eax-browse-podcasts" class="section bg-neutral-200 section-low-top section-low-bottom">
    <div class="container-default w-container">
      <div
        data-w-id="3b24e549-cf4a-aabc-f91e-e98b397677ca"
        style="
          -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
        "
        class="text-center mg-bottom-40px"
      >
        <div class="inner-container _522px center">
          <h2 class="display-2 mg-bottom-8px" :style="`color: var(--${vars.titleColor}) !important;`">
            {{ vars.titleText }}
          </h2>
          <template v-if="descriptions.length > 0">
            <p
              v-for="(item, index) in descriptions"
              :key="index"
              :style="`color: var(--${vars.textColor}) !important;`"
            >
              {{ item.description }}
            </p>
          </template>
        </div>
      </div>
      <div data-w-id="3b24e549-cf4a-aabc-f91e-e98b397677d1" class="grid-4-columns logo-grid">
        <div
          v-for="(item, index) in brands"
          :key="`brand-${index}`"
          class="logo-grid---item-container tw-max-h-[240px] tw-min-h-[240px]"
          :class="{
            'border-bottom-none': index >= brands.length - 4,

            'border-bottom-solid-mbl': index < brands.length - 2,

            'border-none': (index + 1) % 4 === 0,

            'border-right-none-mbl': index % 2 !== 0,
          }"
        >
          <nuxt-link :to="item.link" target="_blank" external class="w-inline-block">
            <NuxtImg :src="item.brand" loading="eager" :width="150" />
          </nuxt-link>
        </div>
      </div>

      <div class="buttons-row center tw-pt-4">
        <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
          <nuxt-link
            :to="button.link"
            :external="true"
            class="w-button"
            :class="`${button.type} ${index === 0 ? 'button-row' : null}`"
          >
            {{ button.label }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'BrandBanner',

  mixins: [ComponentMixin],

  computed: {
    brands() {
      return this.groupedVariables.brands;
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },

    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
