<template>
  <footer class="footer-wrapper">
    <div v-if="vars.footerTopBool" class="container-default w-container">
      <div class="footer-top">
        <div
          id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab25-e76bab22"
          data-w-id="eda65cc1-346b-d98c-3a87-0257e76bab25"
          class="_2-col-span-tablet"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
        >
          <h2 class="display-2 font-size-30px-mbp color-neutral-100">
            {{ vars.footerTopTitleText }}
          </h2>
        </div>
        <div
          id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab2a-e76bab22"
          data-w-id="eda65cc1-346b-d98c-3a87-0257e76bab2a"
          class="width-100 _2-col-span-tablet"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
        >
          <p class="color-neutral-400 mg-bottom-0">
            {{ vars.footerTopDescriptionText }}
          </p>
          <div class="grid-2-columns _2-col-mbl gap-20px tw-pt-4">
            <nuxt-link
              v-for="(tab, index) in tabs"
              :key="`tab-${index}`"
              :external="true"
              id="w-node-_689154db-70a6-0345-c798-55a55b8046ae-5b8046ad"
              :to="tab.link"
              class="footer-download-button w-inline-block"
              ><img
                src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/644a6cebe40c0479893fde40_shadows-icon-style-guide-brix-templates.svg"
                loading="eager"
                width="50"
                height="50"
                alt=""
              />
              <div class="text-300 bold color-neutral-100">
                <span class="font-size-16px-mbp">{{ tab.label }}</span>
              </div>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom-pattern">
      <div class="container-default w-container">
        <div class="footer-middle icebergfooter">
          <div
            id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab31-e76bab22"
            data-w-id="eda65cc1-346b-d98c-3a87-0257e76bab31"
            style="
              transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                skew(0deg, 0deg);
              transform-style: preserve-3d;
              opacity: 1;
            "
          >
            <div class="text-300 bold footer-title">{{ company.name }} Pages</div>
            <div class="footer-pages-container">
              <ul role="list" class="footer-list-wrapper">
                <li class="footer-list-item" v-for="(column1, index) in columns1" :key="`column1-${index}`">
                  <nuxt-link :external="true" :to="column1.link" aria-current="page" class="footer-link w--current">{{
                    column1.item
                  }}</nuxt-link>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab56-e76bab22"
            data-w-id="eda65cc1-346b-d98c-3a87-0257e76bab56"
            style="
              transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                skew(0deg, 0deg);
              transform-style: preserve-3d;
              opacity: 1;
            "
          >
            <div class="text-300 bold footer-title">Additional Links</div>
            <div class="footer-pages-container">
              <ul role="list" class="footer-list-wrapper">
                <li class="footer-list-item" v-for="(column2, index) in columns2" :key="`column2-${index}`">
                  <nuxt-link :external="true" :to="column2.link" class="footer-link">{{ column2.item }}</nuxt-link>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab76-e76bab22"
            data-w-id="eda65cc1-346b-d98c-3a87-0257e76bab76"
            class="footer-card-links-grid"
            style="
              transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                skew(0deg, 0deg);
              transform-style: preserve-3d;
              opacity: 1;
            "
          >
            <a
              id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab77-e76bab22"
              data-w-id="eda65cc1-346b-d98c-3a87-0257e76bab77"
              href="/lifesycle/book-a-demo"
              class="card footer-card-link w-inline-block"
              ><div
                id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab78-e76bab22"
                class="line-rounded-icon diagonal-link-arrow footer-card-link-icon"
              >
                
              </div>
              <div id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab7a-e76bab22" class="display-3 color-neutral-100">
                See Lifesycle in action
              </div></a
            ><a
              id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab7c-e76bab22"
              data-w-id="eda65cc1-346b-d98c-3a87-0257e76bab7c"
              href="/contact"
              class="card footer-card-link w-inline-block"
              ><div
                id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab7d-e76bab22"
                class="line-rounded-icon diagonal-link-arrow footer-card-link-icon"
              >
                
              </div>
              <div id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab7f-e76bab22" class="display-3 color-neutral-100">
                Contact us
              </div></a
            >
          </div>
        </div>
        <div class="footer-bottom">
          <div class="flex align-center vertical-mbp">
            <nuxt-link to="/" :external="true" aria-current="page" class="footer-logo-link w-inline-block w--current">
              <NuxtImg :src="company.logo" loading="eager" :width="136" :height="42" alt="Iceberg Digital"
            /></nuxt-link>
            <div class="vertical-divider horizontal-mbp"></div>
            <div class="social-media-square-links-container">
              <nuxt-link
                :to="vars.facebookUrl"
                :external="true"
                target="_blank"
                class="social-icon-square w-inline-block"
                ><div class="social-icon-font"></div></nuxt-link
              ><nuxt-link
                v-if="vars.xUrl"
                :to="vars.xUrl"
                target="_blank"
                :external="true"
                class="social-icon-square w-inline-block"
                ><div class="social-icon-font">
                  <img
                    src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/64f5b8322e3b266623cb0872_logo-white.png"
                    loading="lazy"
                    width="16"
                    height="16"
                    alt=""
                    srcset="
                      https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/64f5b8322e3b266623cb0872_logo-white-p-500.png   500w,
                      https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/64f5b8322e3b266623cb0872_logo-white-p-800.png   800w,
                      https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/64f5b8322e3b266623cb0872_logo-white-p-1080.png 1080w,
                      https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/64f5b8322e3b266623cb0872_logo-white-p-1600.png 1600w,
                      https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/64f5b8322e3b266623cb0872_logo-white-p-2000.png 2000w,
                      https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/64f5b8322e3b266623cb0872_logo-white.png        2400w
                    "
                    sizes="(max-width: 767px) 16px, (max-width: 991px) 2vw, (max-width: 1439px) 16px, 1vw"
                  /></div
              ></nuxt-link>
              <nuxt-link
                v-if="vars.instagramUrl"
                :external="true"
                :to="vars.instagramUrl"
                target="_blank"
                class="social-icon-square w-inline-block"
                ><div class="social-icon-font"></div></nuxt-link
              >
              <nuxt-link
                v-if="vars.linkedinUrl"
                :external="true"
                :to="vars.linkedinUrl"
                target="_blank"
                class="social-icon-square w-inline-block"
                ><div class="social-icon-font"></div></nuxt-link
              >
            </div>
          </div>
          <div class="color-neutral-100 min-[480px]:tw-text-end">
            Copyright © {{ company.name }} {{ new Date().getFullYear() }}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useWebsiteStore } from '~/units/website/store';
import { ComponentData } from '~/types/index';

export default defineNuxtComponent({
  name: 'Theme0BaseFooterOne',

  mixins: [ComponentMixin],

  props: {
    componentData: {
      type: Object as PropType<ComponentData>,
      required: true,
    },
  },

  computed: {
    ...mapState(useWebsiteStore, ['footerMenuItems']),

    routeUrl() {
      const route = useRoute();
      return route.path;
    },

    columns1() {
      return this.groupedVariables.columns1;
    },

    columns2() {
      return this.groupedVariables.columns2;
    },

    tabs() {
      return this.groupedVariables.tabs;
    },
  },
});
</script>
