<template>
  <div
    class="section floating-icons-sides-section"
    :style="`background-color: var(--${vars.backgroundColor}) !important;`"
  >
    <div class="container-default w-container">
      <div
        data-w-id="a4ca2735-8f77-bc74-8e64-6fb1be33c92c"
        style="
          transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
            skew(0deg, 0deg);
          opacity: 1;
          transform-style: preserve-3d;
        "
        class="text-center"
      >
        <div class="inner-container _624px center">
          <h2 class="display-2 mg-bottom-12px" :style="`color: var(--${vars.titleColor}) !important;`">
            {{ vars.titleText }}
          </h2>
        </div>
        <div class="inner-container _596px center">
          <p v-for="(item, index) in descriptions" :key="index" :style="`color: var(--${vars.textColor}) !important;`">
            {{ item.description }}
          </p>
          <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
            <nuxt-link
              :to="button.link"
              :external="true"
              class="w-button"
              :class="`${button.type} ${index === 0 ? 'button-row' : null}`"
            >
              {{ button.label }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Banner',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
