<template>
  <div
    class="top-section tw-pb-24"
    :style="`background-color: var(--${vars.backgroundColor || ''}) !important; color: var(--${vars.textColor || ''}) !important;`"
  >
    <div class="container-default w-container">
      <div class="grid-4-columns">
        <div
          id="w-node-efd32e07-105a-dc5e-259f-bc6988ab494b-9e5c1e36"
          data-w-id="efd32e07-105a-dc5e-259f-bc6988ab494b"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
          class="card pd-48px---16px text-center"
          v-for="(card, index) in cards"
          :key="`card-${index}`"
        >
          <div class="display-2">
            <strong>{{ card.title }}</strong>
          </div>
          <p class="text-300 medium" :style="`color: var(--${vars.textColor || ''}) !important;`">
            {{ card.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Achievements',

  mixins: [ComponentMixin],

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },
});
</script>
